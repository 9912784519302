.login-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
}

.left-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  color: var(--bs-light);
  background: linear-gradient(to top, #374884 0%, #50619e 100%);

  img {
    width: 100%;
    opacity: 0.05;
  }

  div {
    position: absolute;
    color: var(--color-white);
    font-weight: 8;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin: 0;
      padding: 0;
      font-size: 30px;
    }
    p {
      text-align: center;
      width: 60%;
      font-size: 17px;
      color: var(--color-white);
    }
  }
}

.right-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // position: relative;
  background-color: #fff;
  flex-direction: column;
}

.logo {
  height: 100px;
  width: 100px;
  padding: 10px;
  margin: 10px;
  border-radius: 50%;
  border: 2px solid var(--bs-orange);
  object-fit: cover;
}
.login-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  text-align: center;
  h2 {
    margin: 0;
    padding: 0;
    // border-bottom: 1px solid gray;
    // font-family: '';
  }
  p {
    color: var(--bs-secondary);
    font-size: 14px;
  }
}

.form-control {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}

.form-control > button {
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: var(--bs-link-color);
  color: var(--bs-white);
  cursor: pointer;
  padding: 15px 0px;

  &:hover {
    background-color: var(--bs-link-hover-color);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.auth-recovery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-size: 14px;
  }
  a {
    font-size: 14px;
  }
}

.input-field {
  padding: 0px;
}
.custom-checkbox-root .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.footer-content {
  // position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  font-size: 14px;
}

////responsive behavi. control.

@media screen and (max-width: 1080px) {
  .left-section {
    display: none;
  }
}
