.viewStudentHeader {
  // background-color: var(--bs-primary);
  display: flex;
  align-items: center;
  color: var(--bs-light);
  padding: 10px;
  justify-content: space-between;
}
.viewStudentHeader h4 {
  font-weight: 500;
}
.table td {
  margin: 10px 0 1rem 1rem;
  width: 200px;
}
.table tr {
  margin: 10px 0 1rem 1rem;
}
.paymentbox{
    height: 100px;
    width: 100%;
    border-radius: 10px;
    margin: 1rem;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.paymentboxchild{
  display: flex;
  flex-direction: column;
  height:100% ;
}
.paymentcontain{
    background:linear-gradient(145deg, #e2e8ec, #ffffff);
    overflow-y: scroll;
    max-height: 300px;


}
.paymentStatus{
    display: flex;
    align-items: flex-start;
}
.paymentStatus p:nth-child(1){
    height: 28px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(3, 159, 107);
    border-radius: 20px;
    color: #fff;
    font-size: 11px;
    padding-top: 2px;
    
    margin: 7px;
}
.paymentbox p{
    display: flex;
    justify-content: center;
    
    margin-top: 15px;
}
.paymentDate{
    display: flex;
    margin-left:9px;
    font-size: 12px;
    padding: 2px;
}
.paymentDate p{
    margin-left: 3px;
}

