.inputSeperator{
    margin: 15px 0;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color:var(--bs-light);
    background: var(--bs-primary);
    padding: 6px;

}

.paper{
    border:1px solid var(--bs-gray-400)
    
}

.pageHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    
    & svg{
        cursor: pointer;
        padding: 10px;
        transition: background-color 0.5s;
        border-radius: 50%;
        // background-color: var(--bs-gray-300);
        &:hover{
            background-color: var(--bs-gray-400);
        }
    }
}


.editBtn{
cursor: pointer;
height: 38px;
width: 30px;
padding: 5px;
border-radius: 5px;
border: 1px solid rgb(255, 255, 255);
color: #fff;
background-color: var(--bs-primary);
}

.editBtn:hover{
    background-color: var(--bs-secondary);
    
}