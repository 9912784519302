* {
  /* padding: 0; margin: 0;  */
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

h4,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

html,
body,
#fullheight {
  min-height: 100% !important;
  height: 100%;
}

:root {
  --bs-blue: #5d87ff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #fa896b;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffae1f;
  --bs-green: #39b69a;
  --bs-teal: #20c997;
  --bs-cyan: #3dd9eb;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #777e89;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #ecf2ff;
  --bs-gray-200: #fcfcfc;
  --bs-gray-201: #f6f6f6;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-450: #858585;
  --bs-gray-500: #5a6a85;
  --bs-gray-600: #777e89;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #11142d;
  --bs-primary: #5d87ff;
  --bs-primary1: #5d87ff;
  --bs-secondary: #777e89;
  --bs-success: #39b69a;
  --bs-info: #3dd9eb;
  --bs-warning: #ffae1f;
  --bs-danger: #fa896b;
  --bs-danger2: #ff511c;
  --bs-light: #ecf2ff;
  --bs-dark: #11142d;
  --bs-muted: #5a6a85;
  --bs-indigo: #6610f2;
  --bs-primary-rgb: 93, 135, 255;
  --bs-secondary-rgb: 119, 126, 137;
  --bs-success-rgb: 57, 182, 154;
  --bs-info-rgb: 61, 217, 235;
  --bs-warning-rgb: 255, 174, 31;
  --bs-danger-rgb: 250, 137, 107;
  --bs-light-rgb: 236, 242, 255;
  --bs-dark-rgb: 17, 20, 45;
  --bs-muted-rgb: 90, 106, 133;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-primary-text: #4a6ccc;
  --bs-secondary-text: #777e89;
  --bs-success-text: #2e927b;
  --bs-info-text: #25828d;
  --bs-warning-text: #996813;
  --bs-danger-text: #c86e56;
  --bs-light-text: #777e89;
  --bs-dark-text: #495057;
  --bs-primary-bg-subtle: #dfe7ff;
  --bs-secondary-bg-subtle: #ecf2ff;
  --bs-success-bg-subtle: #d7f0eb;
  --bs-info-bg-subtle: #d8f7fb;
  --bs-warning-bg-subtle: #ffefd2;
  --bs-danger-bg-subtle: #fee7e1;
  --bs-light-bg-subtle: #f6f9ff;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #becfff;
  --bs-secondary-border-subtle: #fcfcfc;
  --bs-success-border-subtle: #b0e2d7;
  --bs-info-border-subtle: #b1f0f7;
  --bs-warning-border-subtle: #ffdfa5;
  --bs-danger-border-subtle: #fdd0c4;
  --bs-light-border-subtle: #fcfcfc;
  --bs-dark-border-subtle: #5a6a85;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 17, 20, 45;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Plus Jakarta Sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #11142d;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(17, 20, 45, 0.75);
  --bs-secondary-color-rgb: 17, 20, 45;
  --bs-secondary-bg: #fcfcfc;
  --bs-secondary-bg-rgb: 252, 252, 252;
  --bs-tertiary-color: rgba(17, 20, 45, 0.5);
  --bs-tertiary-color-rgb: 17, 20, 45;
  --bs-tertiary-bg: #ecf2ff;
  --bs-tertiary-bg-rgb: 236, 242, 255;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-link-color: #5d87ff;
  --bs-link-color-rgb: 93, 135, 255;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #4a6ccc;
  --bs-link-hover-color-rgb: 74, 108, 204;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #ffefd2;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e5eaef;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 7px;
  --bs-border-radius-sm: 8px;
  --bs-border-radius-lg: 15px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
  --bs-emphasis-color: #000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #ffefd2;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
  --sidebar-login-gradient: linear-gradient(224.46deg, #3a495f, #1b2331);
  --colour-background:#F8F9FA;
}

/* //overall-contaoiner */
.main-container {
  display: flex;
  flex-direction: row;
  transition: all 0.5s;
  min-height: 100vh;
}

.sidemenu-tracker {
  margin-left: 260px;
}

.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  overflow: visible;
  justify-content: space-between;
}

.navbar-container {
  position: sticky;
  top: 0;
  z-index: 9;
}

.main-content-area {
  flex: 1;
}

.footer {
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  color: var(--bs-secondary);
}



